import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {CALENDAR_PLAN_CONTEXT} from '@/app/http/calendar-plan-context.token';

@Injectable()
export class CalendarPlanSessionInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const sessionId = req.context.get(CALENDAR_PLAN_CONTEXT);

        if (!sessionId) return next.handle(req); // Don't add header unless explicitly requested

        const modifiedReq = req.clone({
            setHeaders: {
                'X-Session-Id': sessionId
            }
        });

        return next.handle(modifiedReq);
    }
}
